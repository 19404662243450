import React, { useState, useEffect, useContext } from 'react';
import './ProductDisplay.css';
import star_icon from "../Assets/star_icon.png";
import { ShopContext } from '../../Context/ShopContext';

const ProductDisplay = (props) => {
    const { product } = props;
    const { addToCart } = useContext(ShopContext);

    // Initialize the main image with the first image from the product
    const [mainImage, setMainImage] = useState(product.image_urls[0]);

    useEffect(() => {
        setMainImage(product.image_urls[0]);
    }, [product]);

    // Function to swap images
    const handleImageClick = (clickedImage) => {
        setMainImage(clickedImage);
    };

    return (
        <div className='productdisplay'>
            <div className='productdisplay-left'>
                <div className='productdisplay-img-list'>
                    {product.image_urls.slice(0, 4).map((img, index) => (
                        <img 
                            key={index} 
                            src={img} 
                            alt="" 
                            onClick={() => handleImageClick(img)} 
                            className="thumbnail-img"
                        />
                    ))}
                </div>
                <div className='productdisplay-img'>
                    <img className='productdisplay-main-img' src={mainImage} alt="" />
                </div>
            </div>
            <div className='productdisplay-right'>
                <h1>{product.name}</h1>
                <div className='productdisplay-right-stars'>
                    {[...Array(5)].map((_, index) => (
                        <img key={index} src={star_icon} alt="" />
                    ))}
                    <p>(0)</p>
                </div>
                <div className='productdisplay-right-prices'>
                    <div className='productdisplay-right-price-old'></div>
                    <div className='productdisplay-right-price-new'>${product.new_price}</div>
                </div>
                <div className="productdisplay-right-size">
                    <h1>Size</h1>
                    <div className="productdisplay-right-sizes">
                        <h1>{product.size}</h1>
                    </div>
                </div>
                <button onClick={() => addToCart(product.id)}><span>ADD TO CART</span></button>
                <p className="productdisplay-right-category"><span>Category :</span> {product.category}</p>
                <p className="productdisplay-right-category"><span>Tags :</span> Handmade, Crochet</p>
            </div>
        </div>
    );
};

export default ProductDisplay;