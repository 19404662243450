import React from 'react'
import {Link} from 'react-router-dom'
import './Hero.css'
import hero_img1 from '../Assets/bigheroimg.jpeg'
import hero_img2 from '../Assets/deleted/product_10.jpg'
import hero_img3 from '../Assets/product_11.jpeg'
import hero_img4 from '../Assets/deleted/product_24.jpg'
import hero_img5 from '../Assets/product_3.jpg'



const Hero = () => {


  return (
    <div className="container">
      <div className="hero">
        <div className="image-wrapper hero-grid-col-2 hero-grid-row-2"><Link to='/allproducts'>
          <img src={hero_img1} alt="" />
          <span className="image-text">All Products</span>
        </Link></div>
        <div className="image-wrapper"><Link to='/clothes'>
          <img src={hero_img2} alt="" />
          <span className="image-text">Clothes</span>
        </Link></div>
        <div className="image-wrapper"><Link to='/accessories'>
          <img src={hero_img3} alt="" />
          <span className="image-text">Accessories</span>
        </Link></div>
        <div className="image-wrapper"><a href='https://www.etsy.com/shop/MariePineapple#items'>
          <img src={hero_img4} alt="" />
          <span className="image-text">Patterns</span>
        </a></div>
        <div className="image-wrapper"><Link to='/plushies'>
          <img src={hero_img5} alt="" />
          <span className="image-text">Plushies</span>
        </Link></div>
      </div>
    </div>
  )
}

export default Hero