import React , {useEffect, useState} from 'react'
import './RelatedProducts.css'
import Item from '../Item/Item'

const RelatedProducts = () => {
   const [popularProducts, setPopularProducts] = useState([]);
   const [loading, setLoading] = useState(true);
   
     useEffect(()=>{
       fetch('https://backend.mariemadeit.com/popularitems')
       .then((response)=>response.json())
       .then((data)=> {
         setPopularProducts(data);
         setLoading(false);
       });
     },[])
   
     if(loading) {
       return <></>
     }

  return(

    <div className='relatedproducts'>
        <h1>Related Products</h1>
        <hr />
        <div className='relatedproducts-item'>
            {popularProducts?.map((item,i) => {
                return <Item key={i} id={item.id} name={item.name} size={item.size} image={item.image_urls[0]} new_price={item.new_price}/>
            })}
        </div>
    </div>
  )
}

export default RelatedProducts