import React from 'react'
import './DescriptionBox.css'
export const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
        <div className="descriptionbox-navigator">
            <div className="descriptionbox-nav-box">Description</div>
            {/*<div className="descriptionbox-nav-box fade">Reviews (0)</div>*/}
        </div>
        <div className='descriptionbox-description'>
            <p>Every item on this website is handmade by me! If you want any custom made item, send over a PICTURE, COLOR, SIZE, and DESCRIPTION of any item to my <a id='ig-link' href='https://www.instagram.com/mariemadeit__/?hl=en'>Instagram</a> and I will respond ASAP!</p>
        </div>
    </div>
  )
}

export default DescriptionBox
