import React, { useContext, useState } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/cart_cross_icon.png';

const CartItems = () => {
  const { getTotalCartAmount, all_product, cartItems, removeFromCart } = useContext(ShopContext);
  const [isLoading, setIsLoading] = useState(false);

  

  const handleCheckout = async () => {
    setIsLoading(true);
  
    // Helper function to construct the cart payload
    const getCartPayload = () =>
      all_product
        .filter((product) => cartItems[product.id] > 0)
        .map((product) => ({
          name: product.name,
          quantity: cartItems[product.id],
          price: product.new_price,
        }));
  
    try {
      const cartPayload = getCartPayload();
      
  
      // Ensure cart is not empty before making the request
      if (cartPayload.length === 0) {
        alert('Your cart is empty. Please add items before proceeding to checkout.');
        return;
      }
  
      const response = await fetch('https://backend.mariemadeit.com/create-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cart: cartPayload }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout.');
      }
  
      const data = await response.json();
  
      if (data.paymentLinkUrl) {
        // Redirect user to the payment link
        window.location.href = data.paymentLinkUrl;
      } else {
        throw new Error('Payment link not provided. Please try again.');
      }
    } catch (error) {
      console.error('Error during checkout:', error.message);
      alert(`Checkout failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className='cartitems'>
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Color</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {all_product.some((e) => cartItems[e.id] > 0) ? (
        all_product.map((e) => {
          if (cartItems[e.id] > 0) {
            return (
              <div key={e.id}>
                <div className="cartitems-format cartitems-format-main">
                  <img src={e.image_urls[0]} alt='' className='carticon-product-icon' />
                  <p>{e.name}</p>
                  <p>{e.color}</p>
                  <p>${e.new_price}</p>
                  <button className='cartitems-quantity'>{cartItems[e.id]}</button>
                  <p>${(e.new_price * cartItems[e.id]).toFixed(2)}</p>
                  <img
                    className='cartitems-remove-icon'
                    src={remove_icon}
                    onClick={() => { removeFromCart(e.id); }}
                    alt='Remove item'
                  />
                </div>
                <hr />
              </div>
            );
          }
          return null;
        })
      ) : (
        <p>Your cart is empty. Add some items to proceed to checkout.</p>
      )}
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>${getTotalCartAmount().toFixed(2)}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>TBD</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>${getTotalCartAmount().toFixed(2)}</h3>
            </div>
          </div>
          <button onClick={handleCheckout} disabled={isLoading}>
            {isLoading ? 'Processing...' : 'PROCEED TO CHECKOUT'}
          </button>
        </div>
        <div className="cartitems-promocode">
          <p>If you have a promo code, enter it here</p>
          <div className="cartitems-promobox">
            <input type='text' placeholder="Promo code" />
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;